
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import CampoCarregando from '@/core/components/Tela/CampoCarregando.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import PessoaModal from '@/views/Cadastros/Pessoas/PessoaModal.vue';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import { IDTOPessoaCadastroRapido } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaCadastroRapido';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import SelecionarRelacaoPessoa from './SelecionarRelacaoPessoa.vue';
import ServicoUtilitario from '@/servicos/MeuSistema/ServicoUtilitario';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import CampoCpf from '@/core/components/Tela/CampoCpf.vue';
import CampoCnpj from '@/core/components/Tela/CampoCnpj.vue';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import storeSistema from '@/store/storeSistema';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import ServicoCliente from '@/servicos/Cadastros/Pessoas/ServicoCliente';
import ServicoFornecedor from '@/servicos/Cadastros/Pessoas/ServicoFornecedor';
import ServicoTransportadora from '@/servicos/Cadastros/Pessoas/ServicoTransportadora';
import ServicoColaborador from '@/servicos/Cadastros/Pessoas/ServicoColaborador';
import ServicoRepresentante from '@/servicos/Cadastros/Pessoas/ServicoRepresentante';
import { IPessoa } from '@/models/Entidades/Cadastros/Pessoas/IPessoa';
import { IServicoBase } from '@/core/models/IServicoBase';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';
import { IPessoaEndereco } from '@/models/Entidades/Cadastros/Pessoas/IPessoaEndereco';
import { ETipoEndereco } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoEndereco';
import { ETipoInscricaoEstadual } from '@/models/Enumeradores/Compartilhados/ETipoInscricaoEstadual';
import { IEndereco } from '@/models/Entidades/Cadastros/Localizacoes/IEndereco';

export default defineComponent({
  name: 'SelecionarPessoa',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    nomeSelecionado: {
      type: String,
      default: '',
    },
    tipoRelacao: {
      type: Number,
      default: 0,
    },
    codigoRelacao: {
      type: Number,
      default: 0,
    },
    preencherCodigoRelacao: {
      type: Boolean,
      default: false,
    },
    apresentarCpfCnpj: {
      type: Boolean,
      default: true,
    },
    apresentarRelacoes: {
      type: Boolean,
      default: false,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    apresentacaoPersonalizada: {
      type: Boolean,
      default: false,
    },
    apresentarCadastro: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Pessoa',
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    omitirPessoas: {
      type: Array as () => number[],
    },
    recursoOrigem: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    pessoaPreCadastro: {
      type: Object as () => IPessoa,
    },
  },
  components: {
    Icone,
    CampoCarregando,
    SelecionarRelacaoPessoa,
    CampoCpf,
    CampoCnpj,
    PessoaModal,
    RequisicaoModal,
  },
  emits: ['update:codigoSelecionado', 'update:codigoRelacao', 'update:codigosSelecionados', 'update:nomeSelecionado', 'blur', 'change'],
  setup(props, { emit }) {
    const { apresentarMensagemSucesso } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao,
    } = useModalBase(props, emit);

    const {
      selecionarBase, montaOpcoesComListaConsultaRapida, verificacaoPreBusca, verificacaoUltimaPesquisa, aguardarConclusaoCarregamento,
      comportamentoPadraoSemResultado, instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    let debounce = 0;
    const servicoPessoa = new ServicoPessoa();
    const servicoCidade = new ServicoCidade();
    const servicoUtilitario = new ServicoUtilitario();
    let servicoCadastroPessoa:IServicoBase<IPessoa> = new ServicoFornecedor();

    const state = reactive({
      listaPessoas: [] as IOption[],
      descricaoRelacao: '',
      criarPessoa: false,
      apresentarCadastroRapido: false,
      cadastroRapido: {} as IDTOPessoaCadastroRapido,
      cadastrandoPessoa: false,
      documentoCpf: true,
      pessoaPreCadastro: {} as IPessoa,
    });

    state.cadastroRapido.nomeRazaoSocial = '';
    state.cadastroRapido.cpfCnpj = '11111111111';
    state.cadastroRapido.relacoes = [];

    function verificacaoNumero() {
      if (!state.documentoCpf && state.cadastroRapido.cpfCnpj === '11111111111') {
        state.cadastroRapido.cpfCnpj = '11111111111111';
      }
    }

    const computedCodigoRelacao = computed({
      get: () => props.codigoRelacao,
      set: (val: number) => {
        emit('update:codigoRelacao', val);
      },
    });

    async function buscarCodigoRelacao() {
      if (props.codigoSelecionado !== undefined && props.preencherCodigoRelacao) {
        if (props.codigoSelecionado > 0) {
          computedCodigoRelacao.value = await servicoPessoa.obterCodigoRelacao(props.codigoSelecionado, props.tipoRelacao);
        }
      }
    }

    function preencheListaOpcoes():void {
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
        if (props.omitirPessoas !== undefined) {
          props.omitirPessoas.forEach((codigoPessoa) => {
            selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((c) => c.codigo !== codigoPessoa);
          });
        }
        selecionarBase.listaOpcoes = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
      }
      selecionarBase.listaItensConsultaRapida.push({ codigo: 0, textoIdentificacao: 'Cadastrar', informacaoAdicional: '' } as IItemConsultaRapida);
    }

    async function obterPessoaPorCodigo(valor: any) {
      if (!verificacaoPreBusca(valor)) return;

      selecionarBase.listaOpcoes = [];

      const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor, true);
      parametrosConsultaRapida.quantidadeRegistros = 9999999;
      selecionarBase.listaItensConsultaRapida = await servicoPessoa.consultaRapida(parametrosConsultaRapida, props.tipoRelacao, props.apresentarCpfCnpj, props.apresentarRelacoes);
      preencheListaOpcoes();
      comportamentoPadraoSemResultado();
    }

    async function pesquisarPessoa(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;

      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;
      selecionarBase.buscandoDados = true;
      selecionarBase.listaOpcoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa, true);
        if (valorPesquisa.length > 3) {
          parametrosConsultaRapida.quantidadeRegistros = 100;
        }
        selecionarBase.listaItensConsultaRapida = await servicoPessoa.consultaRapida(parametrosConsultaRapida, props.tipoRelacao, props.apresentarCpfCnpj, props.apresentarRelacoes);
        state.criarPessoa = false;
        preencheListaOpcoes();
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        if (!UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
          state.criarPessoa = true;
        }
        selecionarBase.buscandoDados = false;
      }, 600);
    }

    function obterPessoa():any {
      obterPessoaPorCodigo(props.codigoSelecionado);
      buscarCodigoRelacao();
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedNomeSelecionado = computed({
      get: () => props.nomeSelecionado,
      set: (val: string) => {
        emit('update:nomeSelecionado', val);
      },
    });

    function preencherNomeConta(codigo:number) {
      const option = selecionarBase.listaOpcoes.find((c) => c.value === codigo);
      if (option?.label !== undefined && option?.label !== '') {
        computedNomeSelecionado.value = option?.label;
      } else {
        computedNomeSelecionado.value = '';
      }
    }

    const computedCodigoSelecionado = computed({
      get: () => obterPessoa(),
      set: (val: number) => {
        preencherNomeConta(val);
        emit('update:codigoSelecionado', val);
      },
    });

    function obterPessoas():number[] {
      obterPessoaPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterPessoas(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function obterDescricaoRelacao() {
      switch (props.tipoRelacao) {
        case ETipoRelacaoPessoa.Cliente:
          return 'Cliente';
        case ETipoRelacaoPessoa.Colaborador:
          return 'Colaborador';
        case ETipoRelacaoPessoa.Fornecedor:
          return 'Fornecedor';
        case ETipoRelacaoPessoa.Representante:
          return 'Representante';
        case ETipoRelacaoPessoa.Transportadora:
          return 'Transportadora';
        default:
          return '';
      }
    }

    function obterCorRelacao() {
      switch (props.tipoRelacao) {
        case ETipoRelacaoPessoa.Cliente:
          return '#4b55b3';
        case ETipoRelacaoPessoa.Colaborador:
          return '#c677e6';
        case ETipoRelacaoPessoa.Fornecedor:
          return '#77d486';
        case ETipoRelacaoPessoa.Representante:
          return '#fc9003';
        case ETipoRelacaoPessoa.Transportadora:
          return '#b31453';
        default:
          return '';
      }
    }

    function obterIconePessoa():string {
      switch (props.tipoRelacao) {
        case ETipoRelacaoPessoa.Cliente:
          return 'cliente';
        case ETipoRelacaoPessoa.Colaborador:
          return 'cliente';
        case ETipoRelacaoPessoa.Fornecedor:
          return 'cliente';
        case ETipoRelacaoPessoa.Representante:
          return 'cliente';
        case ETipoRelacaoPessoa.Transportadora:
          return 'caminhao';
        default:
          return '';
      }
    }

    function blur() {
      selecionarBase.emDigitacao = false;
      emit('blur');
    }

    async function obterCodigoCidade(nomeCidade: string, uf: string): Promise<number> {
      let codigoCidade = 0;

      const cidade = await servicoCidade.obterCidadePorNomeUf(nomeCidade, uf);
      if (UtilitarioGeral.objetoValido(cidade)) {
        if (UtilitarioGeral.validaCodigo(cidade.codigo)) {
          codigoCidade = cidade.codigo;
        }
      }

      return codigoCidade;
    }

    async function consultaCNPJ() {
      if (state.cadastroRapido.cpfCnpj.length < 14) return;

      const cpfCnpj = UtilitarioGeral.obterApenasNumeros(state.cadastroRapido.cpfCnpj);
      if (cpfCnpj.length === 14) {
        if (cpfCnpj !== '11111111111111') {
          state.cadastrandoPessoa = true;
          const retornoConsultaCnpj = await servicoUtilitario.consultaCNPJ(UtilitarioMascara.removerMascara(state.cadastroRapido.cpfCnpj));
          if (UtilitarioGeral.objetoValido(retornoConsultaCnpj)) {
            if (UtilitarioGeral.valorValido(retornoConsultaCnpj.razaoSocial)) {
              state.cadastroRapido.nomeRazaoSocial = retornoConsultaCnpj.razaoSocial;
              state.cadastroRapido.nomeFantasia = retornoConsultaCnpj.nomeFantasia;
              state.cadastroRapido.email = retornoConsultaCnpj.contato.email;
              state.cadastroRapido.telefone = UtilitarioMascara.removerMascara(retornoConsultaCnpj.contato.telefonePrincipal);
              state.cadastroRapido.contato = retornoConsultaCnpj.qsa[0].nome;
              if (UtilitarioGeral.valorValido(retornoConsultaCnpj.inscricaoEstadual)) {
                state.cadastroRapido.inscricaoEstadual = retornoConsultaCnpj.inscricaoEstadual;
              }
              state.cadastroRapido.cep = retornoConsultaCnpj.endereco.cep;
              state.cadastroRapido.logradouro = retornoConsultaCnpj.endereco.logadouro;
              state.cadastroRapido.complemento = retornoConsultaCnpj.endereco.complemento;
              state.cadastroRapido.bairro = retornoConsultaCnpj.endereco.bairro;
              state.cadastroRapido.numero = retornoConsultaCnpj.endereco.numero;
              state.cadastroRapido.cep = retornoConsultaCnpj.endereco.cep;
              state.cadastroRapido.codigoCidade = await obterCodigoCidade(retornoConsultaCnpj.endereco.cidade, retornoConsultaCnpj.endereco.uf);
            }
          }
          state.cadastrandoPessoa = false;
        }
      }
    }

    async function concluirCadastroRapido() {
      state.cadastroRapido.empresas = props.empresas;
      state.cadastroRapido.recursoOrigem = props.recursoOrigem;
      state.cadastrandoPessoa = true;

      const retorno = await servicoPessoa.cadastroRapido(state.cadastroRapido);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        state.listaPessoas = [];
        const opcaoPessoaCadastrada:IOption = { value: retorno.codigoRegistro, label: state.cadastroRapido.nomeRazaoSocial };
        state.listaPessoas.push(opcaoPessoaCadastrada);

        state.cadastroRapido = {} as IDTOPessoaCadastroRapido;
        state.documentoCpf = true;
        state.cadastroRapido.cpfCnpj = '11111111111';
        state.cadastroRapido.inscricaoEstadual = '';
        state.cadastroRapido.nomeRazaoSocial = '';
        state.cadastroRapido.nomeFantasia = '';
        state.cadastroRapido.relacoes = [];
        state.apresentarCadastroRapido = false;
        state.criarPessoa = false;
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: '', apenasAtivos: true, recursoAssociado: '',
        };
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = String(retorno.codigoRegistro);
        if (UtilitarioGeral.validaLista(props.empresas)) {
          parametrosConsultaRapida.empresas = props.empresas;
        } else {
          parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
        }

        selecionarBase.listaItensConsultaRapida = await servicoPessoa.consultaRapida(parametrosConsultaRapida, props.tipoRelacao, props.apresentarCpfCnpj, props.apresentarRelacoes);
        state.listaPessoas = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
        computedCodigoSelecionado.value = retorno.codigoRegistro;
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarRetornoRequisicao(retorno);
      }
      state.cadastrandoPessoa = false;
    }

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    async function consultaCNPJPreCadastro() {
      const retornoConsultaCnpj = await servicoUtilitario.consultaCNPJ(UtilitarioMascara.removerMascara(state.pessoaPreCadastro.pessoaJuridica.cnpj));
      if (UtilitarioGeral.objetoValido(retornoConsultaCnpj)) {
        if (UtilitarioGeral.valorValido(retornoConsultaCnpj.razaoSocial)) {
          state.pessoaPreCadastro.pessoaJuridica.razaoSocial = retornoConsultaCnpj.razaoSocial;
          state.pessoaPreCadastro.pessoaJuridica.nomeFantasia = retornoConsultaCnpj.nomeFantasia;
          state.pessoaPreCadastro.email = retornoConsultaCnpj.contato.email;
          const telefone = UtilitarioMascara.removerMascara(retornoConsultaCnpj.contato.telefonePrincipal);
          state.pessoaPreCadastro.telefone = UtilitarioMascara.mascararTelefoneFixoOuCelular(telefone);
          if (UtilitarioGeral.validaLista(retornoConsultaCnpj.qsa)) {
            state.pessoaPreCadastro.pessoaJuridica.contato = retornoConsultaCnpj.qsa[0].nome;
          }

          if (UtilitarioGeral.objetoValido(retornoConsultaCnpj)) {
            if (UtilitarioGeral.valorValido(retornoConsultaCnpj.inscricaoEstadual)) {
              state.pessoaPreCadastro.inscricoes.tipoIe = ETipoInscricaoEstadual.ContribuinteICMS;
              state.pessoaPreCadastro.inscricoes.inscricaoEstadual = retornoConsultaCnpj.inscricaoEstadual;
            }

            if (UtilitarioGeral.objetoValido(retornoConsultaCnpj.simples)) {
              state.pessoaPreCadastro.fiscal.simplesNacional = retornoConsultaCnpj.simples.optante;
            }

            state.pessoaPreCadastro.enderecos = [] as IPessoaEndereco[];
            const enderecoFornecedor: IPessoaEndereco = {} as IPessoaEndereco;
            enderecoFornecedor.codigo = new Date().getTime();
            enderecoFornecedor.codigoPessoa = 0;
            enderecoFornecedor.codigoEndereco = 0;
            enderecoFornecedor.tipoEndereco = ETipoEndereco.Principal;
            enderecoFornecedor.identificacao = 'Consulta Receita';
            enderecoFornecedor.endereco = {} as IEndereco;
            enderecoFornecedor.endereco.codigo = 0;
            enderecoFornecedor.endereco.cep = retornoConsultaCnpj.endereco.cep;
            enderecoFornecedor.endereco.logradouro = retornoConsultaCnpj.endereco.logadouro;
            enderecoFornecedor.endereco.complemento = retornoConsultaCnpj.endereco.complemento;
            enderecoFornecedor.endereco.bairro = retornoConsultaCnpj.endereco.bairro;
            enderecoFornecedor.endereco.numero = retornoConsultaCnpj.endereco.numero;
            enderecoFornecedor.endereco.cep = retornoConsultaCnpj.endereco.cep;
            enderecoFornecedor.endereco.codigoCidade = await obterCodigoCidade(retornoConsultaCnpj.endereco.cidade, retornoConsultaCnpj.endereco.uf);
            state.pessoaPreCadastro.enderecos.push(enderecoFornecedor);
          }
        }
      }
    }

    async function cadastrar() {
      if (props.apresentarCadastro) {
        switch (props.tipoRelacao) {
          case ETipoRelacaoPessoa.Cliente:
            servicoCadastroPessoa = new ServicoCliente();
            break;
          case ETipoRelacaoPessoa.Colaborador:
            servicoCadastroPessoa = new ServicoColaborador();
            break;
          case ETipoRelacaoPessoa.Fornecedor:
            servicoCadastroPessoa = new ServicoFornecedor();
            break;
          case ETipoRelacaoPessoa.Representante:
            servicoCadastroPessoa = new ServicoRepresentante();
            break;
          case ETipoRelacaoPessoa.Transportadora:
            servicoCadastroPessoa = new ServicoTransportadora();
            break;
          default:
            break;
        }
        servicoCadastroPessoa.requisicaoSistema();

        computedCodigoSelecionado.value = 0;
        selecionarBase.operacaoCadastro.tipoPermissaoDados = EPermissaoDados.Incluir;
        selecionarBase.operacaoCadastro.listaPermissoesDados = [];
        selecionarBase.operacaoCadastro.codigoRegistro = 0;
        selecionarBase.operacaoCadastro.empresaSelecionada = props.empresas[0];
        selecionarBase.operacaoCadastro.codigoRegistroDuplicar = 0;
        selecionarBase.operacaoCadastro.codigoRegistroPai = 0;
        selecionarBase.operacaoCadastro.requisicaoSistema = true;

        state.pessoaPreCadastro = UtilitarioGeral.instanciaObjetoLocal(props.pessoaPreCadastro);
        if (state.pessoaPreCadastro.tipoPessoa === ETipoPessoa.Juridica) {
          if (UtilitarioGeral.valorValido(state.pessoaPreCadastro.pessoaJuridica.cnpj) && !UtilitarioGeral.valorValido(state.pessoaPreCadastro.pessoaJuridica.razaoSocial)) {
            await consultaCNPJPreCadastro();
          }
        }

        selecionarBase.apresentarCadastro = true;
      } else {
        if (UtilitarioGeral.valorValido(selecionarBase.valorUltimaPesquisa)) {
          const valorDigitado = UtilitarioGeral.obterApenasNumeros(selecionarBase.valorUltimaPesquisa);
          if (valorDigitado.length === 11) {
            state.documentoCpf = true;
            state.cadastroRapido.nomeRazaoSocial = '';
            state.cadastroRapido.cpfCnpj = selecionarBase.valorUltimaPesquisa;
          } else if (valorDigitado.length === 14) {
            state.documentoCpf = false;
            state.cadastroRapido.nomeRazaoSocial = '';
            state.cadastroRapido.cpfCnpj = selecionarBase.valorUltimaPesquisa;
          } else {
            state.cadastroRapido.nomeRazaoSocial = selecionarBase.valorUltimaPesquisa;
          }
        }
        state.apresentarCadastroRapido = true;
      }
    }

    async function cadastroConcluido(codigo:number) {
      const codigoPessoa = await servicoPessoa.obterCodigoPessoaPelaRelacao(codigo, props.tipoRelacao);
      if (props.varios) {
        computedCodigosSelecionados.value.push(codigoPessoa);
      } else {
        computedCodigoSelecionado.value = codigoPessoa;
      }
      computedCodigoRelacao.value = codigo;
      change();
    }

    watch(async () => state.apresentarCadastroRapido, async () => {
      if (state.apresentarCadastroRapido) {
        if (state.cadastroRapido.cpfCnpj.length === 14) {
          await consultaCNPJ();
        }
      }
    });
    return {
      props,
      state,
      modalBase,
      selecionarBase,
      servicoCadastroPessoa,
      aguardarConclusaoCarregamento,
      pesquisarPessoa,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      obterDescricaoRelacao,
      obterCorRelacao,
      blur,
      concluirCadastroRapido,
      verificacaoNumero,
      consultaCNPJ,
      change,
      cadastrar,
      obterIconePessoa,
      cadastroConcluido,
    };
  },
});
